.container {
  display: flex;
  align-self: center;
  align-items: center;
}

.arrow {
  color: var(--palette-common-white);
  height: 24px;
  width: 24px;
}

.pageTitle {
  color: var(--palette-common-white);
  padding-left: 8px;
  display: flex;
  align-self: center;
}

.link {
  color: var(--palette-text-primary) !important;
}
