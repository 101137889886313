.underline {
  text-decoration: underline;
}

.headingText {
  margin-bottom: 4px;
}

.bodyText {
  color: var(--palette-text-secondary);
  margin-top: 0;
  margin-right: 5px;
  margin-bottom: 4px;
  display: inline;
  line-height: 1.3rem;
}

.tertiary {
  text-decoration: none;
  color: black;
}

.tertiary:hover {
  text-decoration: underline;
}

.small {
  margin-bottom: 2px;
  font-size: 13.3px;
}

.medium {
  margin-bottom: 2px;
  font-size: 16px;
}

.large {
  margin-bottom: 5.6px;
  font-size: 16px;
}

.container {
  margin-left: 2px;
}

.callSplit {
  margin-top: -5px;
  color: #ca4004;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  margin-right: 4px;
}

.employer {
  display: flex;
}

.travelStateText {
  padding: 2px;
  border-radius: 2px;
  background: #f6c845;
  color: #2b3949;
  text-align: center;
  font-family: Agipo;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tooltip {
  width: 160px;
  height: 51px;
  padding: 8px;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 2px;
  color: #fff !important;
  background-color: #606a76 !important;
}
